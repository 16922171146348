:root {
  --off-black: #171714;
  --text-title: #000000;
  --text-body: #6f6f6f;
  --placeholder: #a4a5ac;
  --disabled: #e4e4e4;
  --label: #acadb6;
  --line: #d4d9e1;
  --background2: #f3f6fa;
  --background1: #f8f9fc;
  --off-white: #ffffff;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', 'Noto Sans Thai';
  font-size: 16px;
  line-height: 1.5;
  color: var(--text-title);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin: 24px 0;
}

p:first-child {
  margin-top: 0;
}

.format-info {
  white-space: pre-line;
}
